.sk-ww-google-reviews {
  height: auto;
  max-height: 500px;
  overflow-y: auto;
  padding: 16px;
}

.testimonials-container {
  padding: 4rem 2rem; /* Adjust padding for more spacing */
}

.testimonials-container h2 {
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); Add subtle text shadow for depth */
}
